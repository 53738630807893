import React from "react";
const ArticleNotFound: React.FC = () => {
  return (
    <>
      <p>That article doesn't exist. Did you make a typo?</p>
    </>
  );
};

export default ArticleNotFound;
